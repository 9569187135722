import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Row } from "react-bootstrap"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"

const ContactPage = props => {
  const { data } = props
  const { description, body } = data.mdx
  return (
    <Layout location={props.location}>
      <SEO title="Contact" description={description} />
      <Row className="justify-content-center">
        <Col lg={8} md={10} className="mb-4">
          <MDXRenderer>{body}</MDXRenderer>
        </Col>
      </Row>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery {
    mdx(frontmatter: { contentType: { eq: "contact" } }) {
      excerpt(pruneLength: 160)
      body
    }
  }
`
